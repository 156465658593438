import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Homepage from "./Homepage";
import ProvaChatbot from "./ProvaChatbot";
import Progetti from "./Progetti";
import Supporto from "./Supporto";
import Login from "./Login";
import Dispatcher from "./Dispatcher";
import Registration from "./Registration";
import PricingRegistration from "./PricingRegistration";
import Pagamento from "./Pagamento";
import Profile from "./Profile";
import Page404 from "./Page404";
import ModificaProgetto from "./components/ModificaProgetto";
import ProvaChatbotProgetto from "./ProvaChatbotProgetto";

import { useNavigate } from "react-router-dom";
import { StateContext } from "./components/StateContainer";
import ElencoLead from "./ElencoLead";
import DemoCustomer from "./DemoCustomer";
import SqlAgentChat from "./SqlAgentChat";

const RoutesSite = (url) => {
  const { pageTitle, codiceProgetto } = useContext(StateContext);
  const RoutePrivata = ({ url }) => {
    const navigate = useNavigate();

    // Imposta l'URL nel sessionStorage
    sessionStorage.setItem("redirectUrl", window.location.href);

    // Effettua il reindirizzamento
    return <Navigate to='/login' />;
  };
  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/check-token' element={<Dispatcher />} />
      <Route
        path='/dashboard'
        element={
          isAuthenticated ? <Homepage /> : <RoutePrivata url='/dashboard' />
        }
      />
      <Route
        path='/'
        element={
          isAuthenticated ? (
            <Navigate to='/dashboard' />
          ) : (
            <RoutePrivata url='/dashboard' />
          )
        }
      />
      <Route
        path='/test-assistente'
        element={
          isAuthenticated ? (
            <ProvaChatbot />
          ) : (
            <RoutePrivata url='/test-assistente' />
          )
        }
      />
      <Route
        path='/supporto'
        element={
          isAuthenticated ? <Supporto /> : <RoutePrivata url='/supporto' />
        }
      />
      <Route path='/registration' element={<Registration />} />
      <Route
        path='/progetti'
        element={
          isAuthenticated ? <Progetti /> : <RoutePrivata url='/progetti' />
        }
      />
      <Route
        path='/progetti/modifica-progetto/:id'
        element={
          isAuthenticated ? (
            <ModificaProgetto />
          ) : (
            <RoutePrivata
              url={`/progetti/modifica-progetto/${codiceProgetto}`}
            />
          )
        }
      />

      <Route
        path='/progetti/lead'
        element={
          isAuthenticated ? (
            <ElencoLead />
          ) : (
            <RoutePrivata
              url={`/progetti/modifica-progetto/${codiceProgetto}`}
            />
          )
        }
      />

      <Route
        path='/progetti/modifica-progetto/:id/test-assistente'
        element={
          isAuthenticated ? (
            <ProvaChatbotProgetto />
          ) : (
            <RoutePrivata
              url={`/progetti/modifica-progetto/${codiceProgetto}/test-assistente`}
            />
          )
        }
      />

      <Route
        path='/progetti/modifica-progetto/:id/test-assistente'
        element={
          isAuthenticated ? (
            <ProvaChatbotProgetto />
          ) : (
            <RoutePrivata
              url={`/progetti/modifica-progetto/${codiceProgetto}/test-assistente`}
            />
          )
        }
      />

      <Route
        path='/progetti/modifica-progetto/:id/sql-agent'
        element={
          isAuthenticated ? (
            <SqlAgentChat />
          ) : (
            <RoutePrivata
              url={`/progetti/modifica-progetto/${codiceProgetto}/sql-agent`}
            />
          )
        }
      />

      <Route
        path='/pagamento'
        element={
          isAuthenticated ? <Pagamento /> : <RoutePrivata url='/pagamento' />
        }
      />
      <Route
        path='/profile'
        element={
          isAuthenticated ? <Profile /> : <RoutePrivata url='/profile' />
        }
      />

      <Route
        path='/:404'
        element={isAuthenticated ? <Page404 /> : <RoutePrivata url='/:404' />}
      ></Route>

      <Route path='/demo' element={<DemoCustomer />}></Route>
    </Routes>
  );
};

export default RoutesSite;
