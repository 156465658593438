import React, { useEffect, useContext, useState } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { IoCheckmarkDoneCircleSharp as IconaFatto } from "react-icons/io5";
import { Link } from "react-router-dom";

const ModalCreaProgetto = () => {
  const { error, setError, token, nuovoProgetto, setNuovoProgetto } =
    useContext(StateContext);

  const [titoloProgetto, setTitoloProgetto] = useState("");
  const [tipoProgetto, setTipoProgetto] = useState("conversational");
  const [loadingProject, setLoadingProject] = useState(false);

  const closeModalCreateButton = document.getElementById("close-modal-create");

  const inputCrea = document.getElementById("formControlLgcvv");

  const EnterCreaProgetto = (event) => {
    if (event.key === "Enter") {
      CreaProgetto();
    }
  };

  const CreaProgetto = () => {
    setNuovoProgetto([]);
    let lastProjectTitlte = titoloProgetto;
    let lastProjectType = tipoProgetto;
    setTitoloProgetto("");
    setTipoProgetto("");
    setLoadingProject(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/create`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        title: lastProjectTitlte,
        type: lastProjectType
      },
    }).then((response) => {
      console.log("prova", response);
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      } else {
        setLoadingProject(false);
        setError("");
        setNuovoProgetto(response);
        if (closeModalCreateButton) {
          closeModalCreateButton.click();
        }
      }
    });
  };

  const CancellaCronologiaProgetto = () => {
    setNuovoProgetto([]);
    setLoadingProject(false);
    setTitoloProgetto("");
  };

  let modal = document.getElementById("exampleModal");
  let body = document.getElementById("bodyApp");

  const RemoveOverlay = () => {
    modal.classList.add("d-none");
    body.classList.remove("modal-open");
    body.classList.add("overflow-auto");
    CancellaCronologiaProgetto();
  };

  return (
    <div
      className='modal fade overlay'
      id='exampleModal'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
      data-bs-backdrop='false'
      style={{ zIndex: "99999999999999999999999999999999999" }}
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header border-0 py-4'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              id='close-modal-create'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            {loadingProject ? (
              <div className='form-outline'>
                <div className='loading-dots'>
                  <div className='loading-dots--dot'></div>
                  <div className='loading-dots--dot'></div>
                  <div className='loading-dots--dot'></div>
                </div>
              </div>
            ) : (
              <>
              <div className='form-outline'>
                <label className='form-label' htmlFor='formControlLgcvv'>
                  Titolo del progetto
                </label>
                <input
                  type='text'
                  id='formControlLgcvv'
                  className='form-control form-control-lg fs-6'
                  placeholder='Inserisci il titolo del progetto'
                  value={titoloProgetto}
                  onChange={(event) => setTitoloProgetto(event.target.value)}
                  onKeyDown={EnterCreaProgetto}
                />
              </div>
              <div className='form-outline mt-3'>
              <label className='form-label' htmlFor='formControlLgcvv'>
                Tipo di progetto
              </label>
              <select class="form-select" aria-label="Tipo di progetto" value={tipoProgetto} onChange={(event) => setTipoProgetto(event.target.value)}>
                <option value="conversational">Assistente conversazionale</option>
                <option value="mysql-agent">Agente SQL</option>
                <option value="email-agent">Assistente email</option>
              </select>
            </div>
            </>
            )}
          </div>
          <div className='modal-footer border-0'>
            <>
              <button
                type='button'
                className={`btn btn-danger ${loadingProject ? "disabled" : ""}`}
                data-bs-dismiss='modal'
                onClick={CancellaCronologiaProgetto}
              >
                Annulla
              </button>
              <button
                type='button'
                className={`btn bg-green-second text-white ${
                  loadingProject ? "disabled" : ""
                }`}
                onClick={CreaProgetto}
                id='creaProgettoButton'
              >
                Crea progetto
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreaProgetto;
