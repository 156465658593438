import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { SiGoogle, SiFacebook, SiTwitter } from "react-icons/si";
import ImgLogo from "./img/secondo-logo.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import useAuth from "./UseAuth";
import { StateContext } from "./components/StateContainer";
import Cookies from "js-cookie";

function Dispatcher() {
  const { loading, setLoading, error, setError, setPageTitle } =
    useContext(StateContext);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle("Dispatcher");
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token){
      login(token)
    }
  }, []);

  return (
    <div
      className='bg-orange container-fluid' style={{height:"100vh"}}
    >
      <div className="row" style={{height:"100vh"}}>
        <div className="col-12 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-light" role="status" style={{height:"100px", width: "100px"}}>
                <span class="sr-only"></span>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Dispatcher;
